<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card>
        <template v-if="tabs.length === 0">
          <v-skeleton-loader
            type="table-tbody"
          />
        </template>
        <template v-else>
          <v-tabs
            v-model="activeTab"
            show-arrows
          >
            <v-tab
              v-for="(t, key) in tabs"
              :key="key"
            >
              <span>{{ t.name }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="activeTab"
            touchless
          >
            <v-tab-item
              v-for="item in tabs"
              :key="item.id"
            >
              <LockedPortfolio
                v-if="item.locked"
                :id="item.id"
              />
              <Portfolio
                v-else
                :id="item.id"
              />
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import Portfolio from '../components/Portfolio.vue'
import LockedPortfolio from '../components/LockedPortfolio.vue'

export default {
  components: {
    Portfolio,
    LockedPortfolio,
  },

  data() {
    return {
      tab: null,
    }
  },
  computed: {
    activeTab: {
      get() {
        if (this.tab === null) {
          const { portfolios: ids } = this.$store.getters['user/user'] || {}
          const portfolios = this.$store.getters['portfolio/portfolios'] || []

          for (let i = 0; i < portfolios.length; i += 1) {
            if (ids.includes(portfolios[i].id)) return i
          }
        }

        return this.tab
      },
      set(value) {
        this.tab = value
      },
    },
    tabs() {
      const { portfolios: ids } = this.$store.getters['user/user'] || {}
      const portfolios = this.$store.getters['portfolio/portfolios'] || []

      return portfolios.map(p => {
        p.locked = !ids.includes(p.id)

        return p
      })
    },
  },
  created() {
    this.$store.dispatch('portfolio/FETCH_PORTFOLIOS')
    this.$store.dispatch('portfolio/FETCH_INDEXES')
  },
}
</script>
