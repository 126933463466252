<template>
  <div>
    <v-simple-table
      :class="{ 'pt-1': loading }"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(h, i) in headers"
              :key="i"
              :style="`width: ${h.width}%;`"
              :class="hiddenColumns.includes(String(i)) ? 'd-none' : ''"
            >
              <v-hover v-slot="{ hover }">
                <div
                  class="d-flex"
                  style="height: 26px;"
                >
                  <div
                    class="text-subtitle-2 font-weight-semibold"
                    :class="h.class"
                  >
                    <v-chip
                      v-if="h.chip"
                      small
                      :color="color"
                    >
                      {{ h.text }}
                    </v-chip>
                    <template v-else>
                      {{ h.text }}
                    </template>
                  </div>

                  <v-btn
                    v-if="h.sortKey && (hover || (sortField === h.sortKey))"
                    x-small
                    icon
                    :style="sortField !== h.sortKey ? 'color:#bebebe' : ''"
                    @click="sort(h.sortKey)"
                  >
                    <v-icon small>
                      {{ sortField !== h.sortKey ? 'mdi-arrow-up' : sortDirection === 'desc' ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-hover>
            </th>
          </tr>
        </thead>
        <tbody v-if="tableData.length">
          <tr
            v-for="(item, key) in tableData"
            :key="key"
          >
            <td>
              <router-link
                :to="{ name: 'company-summary', params: { symbol: item.symbol } }"
                class="font-weight-semibold text-decoration-none"
                target="_blank"
              >
                {{ item.symbol }}
              </router-link>
            </td>
            <td :class="hiddenColumns.includes('1') ? 'd-none' : ''">
              {{ item.opened_at_text }}
            </td>
            <td :class="hiddenColumns.includes('2') ? 'd-none' : ''">
              {{ item.opening_price_text }}
            </td>
            <td :class="hiddenColumns.includes('3') ? 'd-none' : ''">
              {{ item.closed_at_text }}
            </td>
            <td :class="hiddenColumns.includes('4') ? 'd-none' : ''">
              {{ item.closing_price_text }}
            </td>
            <td :class="hiddenColumns.includes('5') ? 'd-none' : ''">
              <span :class="(item.market_price > item.target ? 'primary-text' : '')">
                {{ item.target_text }}
              </span>
            </td>
            <td :class="hiddenColumns.includes('6') ? 'd-none' : ''">
              <span :class="(item.market_price < item.cut_loss ? 'primary-text' : '')">
                {{ item.cut_loss_text }}
              </span>
            </td>
            <td :class="hiddenColumns.includes('7') ? 'd-none' : ''">
              <span>{{ item.t }}</span>
            </td>
            <td :class="hiddenColumns.includes('8') ? 'd-none' : ''">
              <span
                class="font-weight-semibold"
                :class="(item.gain_loss == 0 ? 'warning' : (item.gain_loss > 0 ? 'success' : 'error')) +'--text'"
              >
                {{ item.gain_loss_text }}
              </span>
            </td>
            <td :class="hiddenColumns.includes('9') ? 'd-none' : ''">
              {{ item.market_price_text }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div class="text-center">
                Không có dữ liệu
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-divider />

    <slot
      name="average"
      :value="average"
    >
    </slot>
  </div>
</template>

<script>

import dayjs from 'dayjs'

export default {

  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'info',
    },
    items: {
      type: Array,
      required: true,
    },
    marketPrices: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      sortField: '',
      sortDirection: '',
      headers: [
        {
          text: this.title,
          width: 12,
          chip: true,
          sortKey: 'symbol',
        },
        {
          text: 'Ngày mở',
          class: 'info--text',
          width: 12,
          sortKey: 'opened_at',
        },
        {
          text: 'Giá mở',
          class: 'info--text',
          width: 10,
          sortKey: 'opening_price',
        },
        {
          text: 'Ngày đóng',
          class: 'success--text',
          width: 12,
          sortKey: 'closed_at',
        },
        {
          text: 'Giá đóng',
          class: 'success--text',
          width: 10,
          sortKey: 'closing_price',
        },
        {
          text: 'Mục tiêu',
          class: 'primary--text',
          width: 10,
          sortKey: 'target',
        },
        {
          text: 'Cắt lỗ',
          class: 'primary--text',
          width: 10,
          sortKey: 'cut_loss',
        },
        {
          text: 'T+',
          width: 6,
          sortKey: 't',
        },
        {
          text: '% Lãi/lỗ',
          width: 10,
          sortKey: 'gain_loss',
        },
        {
          text: 'Thị giá',
          width: 8,
          sortKey: 'market_price',
        },
      ],
    }
  },

  computed: {
    tableData() {
      if (this.items == null) return []
      const result = []
      this.items.forEach(item => {
        const marketPrice = this.marketPrices[item.symbol] || null

        const currentPrice = item.is_closed ? item.closing_price : marketPrice
        item.gain_loss = currentPrice ? ((currentPrice - item.opening_price) / item.opening_price) : null

        // Display values
        item.opened_at_text = dayjs(item.opened_at).format('DD/MM/YYYY')
        item.opening_price_text = (item.opening_price / 1000).toFixed(2)
        item.closed_at_text = item.closed_at ? dayjs(item.closed_at).format('DD/MM/YYYY') : '---'
        item.closing_price_text = item.closing_price ? (item.closing_price / 1000).toFixed(2) : '---'
        item.cut_loss_text = (item.cut_loss / 1000).toFixed(2)
        item.target_text = (item.target / 1000).toFixed(2)
        item.gain_loss_text = item.gain_loss !== null ? `${(item.gain_loss * 100).toFixed(2)}%` : '---'
        item.market_price_text = item.is_closed ? '---' : (marketPrice / 1000).toFixed(2)

        result.push(item)
      })

      const { sortField, sortDirection } = this
      if (sortField && sortDirection) {
        return this.$_.orderBy(result, [sortField], [sortDirection])
      }

      return result
    },

    hiddenColumns() {
      const hidden = this.$route.query.h || ''

      return hidden.split(',')
    },

    average() {
      if (!this.tableData || !this.tableData.length) return 0
      const sum = this.tableData.reduce((acc, item) => acc + item.gain_loss, 0)

      return ((sum / this.tableData.length) * 100).toFixed(2) || 0
    },

  },

  methods: {
    sort(field) {
      const sortDirection = field === this.sortField ? this.sortDirection : ''
      const steps = {
        '': 'asc',
        asc: 'desc',
        desc: '',
      }
      this.sortDirection = steps[sortDirection]
      this.sortField = this.sortDirection !== '' ? field : ''
    },
  },
}
</script>

<style scoped>

  ::v-deep .v-date-picker-table .v-btn {
    min-width: 64px;
  }

</style>
