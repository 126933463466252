<template>
  <v-card
    :loading="loading"
  >
    <Table
      title="Đang đầu tư"
      color="success"
      :is-open="true"
      :items="openItems || []"
      :market-prices="market_prices"
    />
    <Table
      title="Đã chốt lãi lỗ"
      color="info"
      :is-open="false"
      :items="items || []"
      :market-prices="{}"
    >
      <template v-slot:average="{ value: average }">
        <v-row class="pa-3">
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="480px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  text
                  v-on="on"
                >
                  <v-icon
                    left
                  >
                    mdi-calendar-blank-outline
                  </v-icon>
                  {{ dateText }}
                </v-btn>
              </template>
              <v-date-picker
                v-model="date"
                :show-current="true"
                locale="vi"
                type="month"
                :landscape="landscape"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal = false"
                >
                  Đóng
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="pickDate(date)"
                >
                  Chọn
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
            class="d-flex align-center justify-md-end"
          >
            <span class="text-subtitle-2 font-weight-semibold pr-2 ms-5">Hiệu quả tháng</span>
            <span
              class="font-weight-semibold"
              :class="(average == 0 ? 'warning' : (average > 0 ? 'success' : 'error')) +'--text'"
            >
              {{ average }}%
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
            class="d-flex align-center justify-md-end"
          >
            <span class="text-subtitle-2 font-weight-semibold pr-2 ms-5">VNIndex tháng</span>
            <span
              class="font-weight-semibold"
              :class="(vnindex == 0 ? 'warning' : (vnindex > 0 ? 'success' : 'error')) +'--text'"
            >
              {{ vnindex ? vnindex : '---' }}%
            </span>
          </v-col>
        </v-row>
      </template>
    </Table>
  </v-card>
</template>

<script>

import dayjs from 'dayjs'
import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import Table from './Table.vue'

Vue.use(new VueSocketIO({
  connection: SocketIO('https://bgdatafeed.vps.com.vn', { forceNew: true, transports: ['websocket'] }),
}))

export default {
  components: {
    Table,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      modal: false,

      items: null,
      openItems: null,
      loading: false,
      market_prices: {},
    }
  },

  computed: {
    vnindex() {
      const date = this.date.split('-')
      const year = date[0]
      const month = parseInt(date[1], 10)
      const term = `${month}_${year}`

      return this.$store.getters['portfolio/getMonthEfficiency'](term)
    },

    dateText() {
      return `Tháng ${dayjs(this.date).format('M - YYYY')}`
    },

    stockList() {
      if (this.openItems == null) return []
      const result = []
      this.openItems.forEach(item => {
        if (!item.is_closed) {
          result.push(item.symbol)
        }
      })

      return result
    },

    landscape() {
      return this.$vuetify.breakpoint.smAndUp
    },
  },

  watch: {
    stockList(val) {
      this.fetchMarketPrice(val)
      this.join(val)
    },
  },

  created() {
    this.fetchOpenPortfolio()
    this.fetchPortfolio()
  },

  sockets: {
    stock({ data }) {
      this.$set(this.market_prices, data.sym, data.lastPrice * 1000)
    },
  },

  methods: {
    pickDate(date) {
      this.$refs.dialog.save(date)
      this.fetchPortfolio()
    },

    fetchOpenPortfolio() {
      const params = {
        status: 'open',
      }

      this.loading = true
      this.$httpUser.get(`/api/portfolios/${this.id}`, { params })
        .then(resp => {
          const { data } = resp.data
          this.openItems = data
        })
        .catch(() => {
          this.openItems = []
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchPortfolio() {
      const date = this.date.split('-')
      const year = date[0]
      const month = date[1]
      const params = {
        month,
        year,
        status: 'closed',
      }

      this.loading = true
      this.$httpUser.get(`/api/portfolios/${this.id}`, { params })
        .then(resp => {
          const { data } = resp.data
          this.items = data
        })
        .catch(() => {
          this.items = []
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchMarketPrice(symbols) {
      this.$http.get(`https://bgapidatafeed.vps.com.vn/getliststockdata/${symbols.join(',')}`)
        .then(resp => {
          if (!resp.data) return
          const prices = {}
          resp.data.forEach(stock => {
            prices[stock.sym] = stock.lastPrice * 1000
          })
          this.market_prices = prices
        })
        .catch(e => {
          console.error(e.message)
        })
    },

    join(symbols) {
      const message = {
        action: 'join',
        list: symbols.join(','),
      }
      this.$socket.emit('regs', JSON.stringify(message))
    },
  },
}
</script>

<style scoped>

  ::v-deep .v-date-picker-table .v-btn {
    min-width: 64px;
  }

</style>
